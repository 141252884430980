import { getPostsForSearch, PostType } from '@space-cow-media/wp-components'
import { GetServerSidePropsContext } from 'next'

import Container from '@/components/Container/Container'
import { ExplicitHead, ExplicitHeadProps } from '@/components/DefaultHead/DefaultHead'
import Main from '@/components/Main/Main'
import SimpleHeader from '@/components/SimpleHeader/SimpleHeader'
import ArticleSearch from '@/components/Wordpress/ArticleSearch/ArticleSearch'
import ArticlesTypePage from '@/components/Wordpress/ArticleTypePage/ArticleTypePage'
import { ARTICLES_CACHE_CONTROL } from '@/utils/constants'

type Props = {
  enableAdhesion: boolean
  headProps?: ExplicitHeadProps
  pageCount: number
  pageNum: number
  posts: PostType[]
  searchTerm?: string
}

export const ArticlesPage = ({ enableAdhesion, headProps, pageCount, pageNum, posts, searchTerm }: Props) => (
  <Main>
    <Container>
      <ExplicitHead
        description='Strategy articles and community content for EDH / Commander'
        keywords='EDH,Commander,MTG,Magic,articles,strategy,content'
        title='Articles'
        {...headProps}
      />
      <div className='mx-3'>
        <SimpleHeader className='d-flex gap-3 mb-3'>
          Articles
          <ArticleSearch initSearchTerm={searchTerm} />
        </SimpleHeader>
        <ArticlesTypePage
          pageCount={pageCount}
          pageNum={pageNum}
          path={searchTerm ? `search/${searchTerm}` : 'page'}
          posts={posts}
        />
      </div>
    </Container>
    {enableAdhesion && <div className='mvHomeAdhesion' />}
  </Main>
)

export const getServerSideProps = async ({ params, res }: GetServerSidePropsContext) => {
  const searchTerm = (params?.searchTerm || '') as string
  let page = params?.page as string | undefined

  if (page === '1') {
    if (searchTerm) {
      return {
        redirect: {
          destination: `/articles/search/${searchTerm}`,
          permanent: true,
        },
      }
    }
    return {
      redirect: {
        destination: `/articles`,
        permanent: true,
      },
    }
  } else if (page === undefined) {
    page = '1'
  }

  res.setHeader('Cache-Control', ARTICLES_CACHE_CONTROL)

  const pageNum = parseInt(page, 10)
  const { pageCount, posts } = await getPostsForSearch({ categoryId: 4, pageNum, search: searchTerm })

  return {
    props: {
      enableAdhesion: true,
      pageCount,
      pageNum,
      posts,
      searchTerm,
    },
  }
}

export default ArticlesPage
